import React from "react";
import { Node, nodeIdOrParentChecked, someChildrenChecked, Tree } from "../../../../utils/tree";
import CollapseIcon from "@mui/icons-material/IndeterminateCheckBox";
import ExpandIcon from "@mui/icons-material/AddBox";
import { Box, Checkbox } from "@mui/material";
import css from "./hierarchy.module.scss";
import { theme } from "../../../../theme/theme";

interface HierarchyProps {
  tree: Tree;
  nodes: Node[];
  openedNodeIds: Set<number>;
  checkedNodeIds: Set<number>;
  setChecked: (id: number, checked: boolean) => void;
  setOpened: (id: number, opened: boolean) => void;
  searchTerm?: string;  // Optional prop
  disabled?: boolean;   // <-- NEW prop
}

const HierarchyPure: React.FC<HierarchyProps> = ({
  tree,
  nodes,
  openedNodeIds,
  checkedNodeIds,
  setChecked,
  setOpened,
  searchTerm,
  disabled = false,  // default false if not provided
}) => {
  // Escape function for regex special characters
  const escapeRegExp = (str: string): string =>
    str.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");

  const highlightSearchTerm = (label: string, term?: string): React.ReactNode => {
    if (!term) return label;
    const escapedSearchTerm = escapeRegExp(term);
    const regex = new RegExp(`(${escapedSearchTerm})`, "gi");
    const parts = label.split(regex);

    return parts.map((part, index) =>
      part.toLowerCase() === term.toLowerCase() ? (
        <span key={index} style={{ backgroundColor: theme.palette.secondary.main, fontWeight: "bold" }}>
          {part}
        </span>
      ) : (
        part
      )
    );
  };

  return (
    <ul className={css.hierarchy}>
      {nodes.map((node) => {
        const { id, label, children } = node;
        const opened = openedNodeIds.has(id);
        const checked = nodeIdOrParentChecked(tree, checkedNodeIds, id);

        // If disabled is true, we won't allow toggling
        const handleClickExpand = disabled
          ? undefined
          : () => setOpened(id, !opened);

        const handleCheck = disabled
          ? undefined
          : () => setChecked(id, !checked);

        return (
          <React.Fragment key={id}>
            <li>
              {children.length > 0 ? (
                <span onClick={handleClickExpand}>
                  {opened ? <CollapseIcon /> : <ExpandIcon />}
                </span>
              ) : (
                // spacing for no children
                <Box width="20px" minHeight="1px" sx={{ float: "left" }} />
              )}
              <Checkbox
                color="primary"
                disabled={disabled}
                checked={checked}
                indeterminate={!checked && someChildrenChecked(node, checkedNodeIds)}
                onChange={handleCheck}
              />
              <span
                onClick={handleClickExpand}
                className={children.length > 0 ? css.expandable : undefined}
              >
                {highlightSearchTerm(label, searchTerm)}
              </span>
            </li>

            {opened && (
              <Hierarchy
                tree={tree}
                nodes={children}
                openedNodeIds={openedNodeIds}
                checkedNodeIds={checkedNodeIds}
                setChecked={setChecked}
                setOpened={setOpened}
                searchTerm={searchTerm}
                disabled={disabled}  // pass it down recursively
              />
            )}
          </React.Fragment>
        );
      })}
    </ul>
  );
};

export const Hierarchy = HierarchyPure;
