// SupplierBulletinDetail.tsx

import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import {
  Container,
  Typography,
  CircularProgress,
  Backdrop,
  Box,
  Button,
  Grid,
  Link,
  List,
  ListItem,
  ListItemText,
  Checkbox,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableContainer,
  Paper,
  FormControlLabel,
  Slide,
  Chip,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useApi } from '../../../utils/hooks/useApi';
import { useUserContext } from '../../../utils/contexts/UserContext';
import { SupplierBulletin, UserInterest } from './datatypes';
import { useAuth0 } from '@auth0/auth0-react';
import SupplierBulletinUserDetails from './SupplierBulletinUserDetails';
import { StyledChip } from '../../../components/StyledChip';
import { useReferenceDataContext } from '../../../utils/contexts/ReferenceDataContext';

const SupplierBulletinDetail: React.FC = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const bulletinExternalId = queryParams.get('bulletinExternalId');

  const [bulletin, setBulletin] = useState<SupplierBulletin | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [showCpvKoder, setShowCpvKoder] = useState<boolean>(false);
  const [selectedCpvKoder, setSelectedCpvKoder] = useState<number[]>([]);
  const {
    get: getBulletin,
    post: addUserInterest,
    del: removeUserInterest,
    error,
    loading: apiLoading,
  } = useApi(`/api/bulletin/byExternalRef/${bulletinExternalId}`);
  const navigate = useNavigate();
  const { finndoffUser, isLoading } = useUserContext();
  const { loginWithRedirect, isAuthenticated } = useAuth0();
  const [hasSuppliers, setHasSuppliers] = useState<boolean>(false);
  const [selectedUserInterest, setSelectedUserInterest] = useState<UserInterest | null>(null);
  const { steder } = useReferenceDataContext();
  
  useEffect(() => {
    if (!isLoading && !isAuthenticated) {
      loginWithRedirect({
        authorizationParams: {
          redirect_uri: `${window.location.origin}${window.location.pathname}`,
        },
        appState: { returnTo: `${window.location.pathname}${window.location.search}` },
      });
    } else if (isAuthenticated) {
      const fetchBulletin = async () => {
        const result = await getBulletin();
        if (result) {
          setBulletin(result);
        }
        setLoading(false);
      };

      if (bulletinExternalId) {
        fetchBulletin().catch(console.error);
      }
    }
  }, [isLoading, isAuthenticated, bulletinExternalId, getBulletin, loginWithRedirect]);

  const handleAddInterest = async () => {
    const confirm = window.confirm(
      'Firmaet som har opprettet bulletinen vil kunne se kontaktinfo slik at de kan kontakte dere. Ønsker du å fortsette?'
    );
    if (confirm) {
      await addUserInterest(`/addUserInterest`, {
        CpvKodeIds: selectedCpvKoder,
        hasSuppliers: hasSuppliers,
      });
      const result = await getBulletin();
      if (result) {
        setBulletin(result);
      }
    }
  };

  const handleRemoveInterest = async () => {
    await removeUserInterest(`/removeUserInterest`);
    const result = await getBulletin();
    if (result) {
      setBulletin(result);
    }
  };

  const handleCpvKodeChange = (id: number) => {
    setSelectedCpvKoder((prev) => {
      if (prev.includes(id)) {
        return prev.filter((cpvId) => cpvId !== id);
      } else {
        return [...prev, id];
      }
    });
  };

  const isUserInterested = bulletin?.userInterests?.some((user) => user.id === finndoffUser?.id);
  const isUserCreator = bulletin?.creator?.id === finndoffUser?.id;
  const isApplicationDeadlinePassed =
    bulletin?.applicationDeadline && new Date(bulletin.applicationDeadline) < new Date();
  const isLimitDatePassed = bulletin?.limitDate && new Date(bulletin.limitDate) < new Date();

  const handleUserDetailsOpen = (user: UserInterest) => {
    console.log(user?.cpvKoder?.length);
    setSelectedUserInterest(user);
  };

  const handleUserDetailsClose = () => {
    setSelectedUserInterest(null);
  };

  const handleUserInterestSave = async () => {
    // Refresh the bulletin data after saving
    const result = await getBulletin();
    if (result) {
      setBulletin(result);
    }
    handleUserDetailsClose();
  };

  // Function to map status values to display labels (for user interests)
  const getStatusLabel = (status: string) => {
    switch (status) {
      case 'NotContacted':
        return 'Ikke kontaktet';
      case 'Selected':
        return 'Valgt';
      case 'Declined':
        return 'Avslått';
      default:
        return status;
    }
  };

  // Function to map bulletin status values to Norwegian
  const getBulletinStatusLabel = (status: string) => {
    switch (status) {
      case 'Open':
        return 'Åpen';
      case 'Closed':
        return 'Lukket';
      case 'Cancelled':
        return 'Avlyst';
      default:
        return status;
    }
  };

  if (loading || isLoading) {
    return (
      <Backdrop
        open={true}
        style={{
          zIndex: 1000,
          position: 'absolute',
          backgroundColor: 'rgba(0, 0, 0, 0.85)',
        }}
      >
        <CircularProgress sx={{ color: 'white' }} />
        <Typography variant="h6" style={{ marginTop: '10px', color: 'white' }}>
          Laster søknad...
        </Typography>
      </Backdrop>
    );
  }

  if (!bulletin) {
    return <Typography variant="h6">Ingen bulletin funnet</Typography>;
  }

  return (
    <Container>
      {(loading || apiLoading) && (
        <Backdrop
          open={loading || apiLoading}
          style={{
            zIndex: 1000,
            position: 'absolute',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: 'rgba(0, 0, 0, 0.85)',
          }}
        >
          <CircularProgress sx={{ color: 'white' }} />
          <Typography variant="h6" style={{ marginTop: '10px', color: 'white' }}>
            Laster søknad...
          </Typography>
        </Backdrop>
      )}
      {bulletin && (
        <Grid container justifyContent="center">
          <Grid item xs={12} sm={10} md={8}>
            <Box sx={{ mt: 4, pb: 4 }}>
              {/* Use a Box to wrap the content and handle transitions */}
              <Box sx={{ position: 'relative' }}>
                {/* Main Content */}
                <Slide
                  direction="left"
                  in={!selectedUserInterest}
                  mountOnEnter
                  unmountOnExit
                  timeout={300}
                >
                  <Box>
                    <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: 2 }}>
                      <Link
                        component="button"
                        variant="body2"
                        onClick={() => navigate(-1)}
                        sx={{ cursor: 'pointer', marginRight: 2 }}
                      >
                        Tilbake til listen
                      </Link>
                    </Box>
                    <Typography variant="h4">
                      {bulletin.title}
                    </Typography>
                    <Typography variant="body2" gutterBottom style={{ marginBottom: '20px' }}>
                      {bulletin.industry?.name}
                    </Typography>
                    <Typography variant="body2" style={{ marginBottom: '10px' }}>
                      <strong>Interesserte underleverandører: </strong>
                      {bulletin.userInterests?.length}
                    </Typography>
                    <Typography variant="body2" style={{ marginBottom: '10px' }}>
                      <strong>Opprettet: </strong>
                      {new Date(bulletin.createdAt).toLocaleDateString()}
                    </Typography>
                    <Typography variant="body2" style={{ marginBottom: '10px' }}>
                      <strong>Oppdatert: </strong>
                      {new Date(bulletin.updatedAt).toLocaleDateString()}
                    </Typography>
                    <Typography variant="body2" style={{ marginBottom: '10px' }}>
                      <strong>Tilbudsfrist: </strong>
                      {bulletin.limitDate
                        ? new Date(bulletin.limitDate).toLocaleDateString()
                        : 'N/A'}
                    </Typography>
                    <Typography variant="body2" style={{ marginBottom: '10px' }}>
                      <strong>Søknadsfrist: </strong>
                      {bulletin.applicationDeadline
                        ? new Date(bulletin.applicationDeadline).toLocaleDateString()
                        : 'N/A'}
                    </Typography>
                    <Typography variant="body2" style={{ marginBottom: '10px' }}>
                      <strong>Beskrivelse: </strong>
                      {bulletin.description}
                    </Typography>
                    <Typography variant="body2" style={{ marginBottom: '10px' }}>
                      <strong>Søknadstatus: </strong> {getBulletinStatusLabel(bulletin.status)}
                    </Typography>
                    {bulletin.steder?.length > 0 && (
                      <Box width="100%" marginBottom="2rem">
                        <Typography variant="h6" gutterBottom>
                          Steder
                        </Typography>

                        <Box display="flex" flexWrap="wrap" gap={1}>
                          {Array.from(bulletin.steder).map((stedId) => {
                            const node = steder.nodeMap[stedId.id];
                            // Make sure node exists before rendering
                            if (!node) return null;
                            return (
                              <StyledChip
                                key={`sted_chip_${stedId}`}
                                label={node.label}
                              />
                            );
                          })}
                        </Box>
                      </Box>
                    )}

                    {bulletin.cpvKoder?.length > 0 && (
                      <Box width="100%" marginBottom="2rem">
                        <Typography variant="h6" gutterBottom>
                          CPV-koder
                        </Typography>
                        <Box display="flex" flexWrap="wrap" gap={1}>
                          {bulletin.cpvKoder.map((cpv) => (
                            <StyledChip
                              key={cpv.id}
                              // Show both code and description, e.g. "12345678 - Tjenester"
                              label={`${cpv.kode} - ${cpv.beskrivelse}`}
                            />
                          ))}
                        </Box>
                      </Box>
                    )}

                    {!isUserCreator &&
                      (isApplicationDeadlinePassed ? (
                        <Typography
                          variant="body2"
                          style={{ marginTop: '10px', color: 'red' }}
                        >
                          Søknadsperioden er lukket
                        </Typography>
                      ) : isUserInterested ? (
                        <>
                          <Typography variant="h5">
                            <strong>Status på din interesse</strong>
                          </Typography>
                          {/* Display status and reason if user is interested */}
                          {bulletin.userInterests
                            ?.filter((user) => user.id === finndoffUser?.id)
                            .map((user) => (
                              <Box key={user.id} sx={{ mt: 2 }}>
                                <Typography variant="body2">
                                  <strong>Status:</strong>{' '}
                                  <Chip
                                    label={getStatusLabel(user.status)}
                                    size="small"
                                    color={
                                      user.status === 'Selected'
                                        ? 'success'
                                        : user.status === 'Declined'
                                        ? 'error'
                                        : 'default'
                                    }
                                    sx={{ ml: 1 }}
                                  />
                                </Typography>
                                {user.statusReason && (
                                  <Typography variant="body2">
                                    <strong>Årsak:</strong> {user.statusReason}
                                  </Typography>
                                )}
                                {/* Conditionally render the "Fjern interesse" button */}
                                {!isLimitDatePassed && user.status === 'NotContacted' && (
                                  <Button
                                    variant="outlined"
                                    color="primary"
                                    onClick={handleRemoveInterest}
                                    sx={{ mt: 2, mb: 2 }}
                                  >
                                    Fjern interesse
                                  </Button>
                                )}
                              </Box>
                            ))}
                        </>
                      ) : (
                        <div>
                          {!showCpvKoder ? (
                            <Button
                              variant="contained"
                              color="primary"
                              onClick={() => setShowCpvKoder(true)}
                              sx={{ mt: 2, mb: 2 }}
                            >
                              Vis interesse
                            </Button>
                          ) : (
                            <IconButton
                              onClick={() => setShowCpvKoder(false)}
                              sx={{ mt: 2, mb: 2 }}
                            >
                              <CloseIcon />
                            </IconButton>
                          )}
                          {showCpvKoder &&(
                            <Box>
                              <Typography variant="h6">Velg CPV-koder:</Typography>
                              <TableContainer component={Paper}>
                                <Table>
                                  <TableHead>
                                    <TableRow>
                                      <TableCell padding="checkbox"></TableCell>
                                      <TableCell>CPV Kode</TableCell>
                                      <TableCell>Beskrivelse</TableCell>
                                    </TableRow>
                                  </TableHead>
                                  <TableBody>
                                    {Array.from(
                                      new Set(bulletin.tender?.cpvKoder?.map((cpv) => cpv.id))
                                    ).map((cpvId) => {
                                      const cpv = bulletin.tender?.cpvKoder?.find(
                                        (cpvItem) => cpvItem.id === cpvId
                                      );
                                      if (!cpv) return null;
                                      return (
                                        <TableRow
                                          key={cpv.id}
                                          hover
                                          onClick={() => handleCpvKodeChange(cpv.id)}
                                          sx={{ cursor: 'pointer', height: 40 }}
                                        >
                                          <TableCell padding="checkbox">
                                            <Checkbox
                                              checked={selectedCpvKoder.includes(cpv.id)}
                                              onChange={() => handleCpvKodeChange(cpv.id)}
                                              name={cpv.kode}
                                              onClick={(e) => e.stopPropagation()}
                                            />
                                          </TableCell>
                                          <TableCell>{cpv.kode}</TableCell>
                                          <TableCell>{cpv.beskrivelse}</TableCell>
                                        </TableRow>
                                      );
                                    })}
                                  </TableBody>
                                </Table>
                              </TableContainer>
                              <FormControlLabel
                                label="Vi har underleverandører"
                                control={
                                  <Checkbox
                                    checked={hasSuppliers}
                                    onChange={() => setHasSuppliers(!hasSuppliers)}
                                  />
                                }
                              />

                              <Box sx={{ mt: 2 }}>
                                <Button
                                  variant="contained"
                                  color="primary"
                                  onClick={handleAddInterest}
                                >
                                  Vis interesse
                                </Button>
                              </Box>
                            </Box>
                          )}
                        </div>
                      ))}
                    {isUserCreator && (
                      <Box sx={{ mt: 4 }}>
                        {bulletin.userInterests.length > 0 ? (
                          <Typography variant="h6">Interesserte underleverandører:</Typography>
                        ) : (
                          <Typography variant="h6">Ingen interessenter registrert enda</Typography>
                        )}
                        <List>
                          {bulletin.userInterests?.map((user: UserInterest) => (
                            <ListItem key={user.id} divider>
                              <ListItemText
                                primary={
                                  <>
                                    {user.firmaNavn}{' '}
                                    <Chip
                                      label={getStatusLabel(user.status)}
                                      size="small"
                                      color={
                                        user.status === 'Selected'
                                          ? 'success'
                                          : user.status === 'Declined'
                                          ? 'error'
                                          : 'default'
                                      }
                                      sx={{ ml: 1 }}
                                    />
                                  </>
                                }
                                secondary={`Organisasjonsnummer: ${user.orgNummer}`}
                              />
                              <Button onClick={() => handleUserDetailsOpen(user)}>
                                Detaljer &gt;
                              </Button>
                            </ListItem>
                          ))}
                        </List>
                      </Box>
                    )}
                  </Box>
                </Slide>

                {/* SupplierBulletinUserDetails Component */}
                {selectedUserInterest && (
                  <Slide
                    direction="left"
                    in={!!selectedUserInterest}
                    mountOnEnter
                    unmountOnExit
                    timeout={300}
                  >
                    <Box>
                      <SupplierBulletinUserDetails
                        userInterest={selectedUserInterest}
                        supplierBulletinExternalRef={bulletin.externalReference}
                        onClose={handleUserDetailsClose}
                        onSave={handleUserInterestSave}
                      />
                    </Box>
                  </Slide>
                )}
              </Box>
            </Box>
          </Grid>
        </Grid>
      )}
    </Container>
  );
};

export default SupplierBulletinDetail;
