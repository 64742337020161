import React, { FunctionComponent } from "react";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  Grid,
  Link,
  Typography,
} from "@mui/material";
import LanIcon from "@mui/icons-material/Lan";
import { useApi } from "../../../utils/hooks/useApi";
import { useSnackbar } from "../../../utils/hooks/useSnackbar";
import { formatISODate } from "../../../utils/formatISODate";
import { FinndoffUser } from "../../../utils/contexts/UserContext";
import { SupplierBulletin } from "../SupplierBulletins/datatypes";

interface SupplierBulletinCardProps {
  bulletin: SupplierBulletin;
  finndoffUser: FinndoffUser;
  onRemoveBulletin: (id: number) => void;
}

export const SupplierBulletinCard: FunctionComponent<SupplierBulletinCardProps> = ({
  bulletin,
  finndoffUser,
  onRemoveBulletin,
}) => {
  const { error, response, del } = useApi("/api/bulletin");
  const { enqueueSuccessMsg, enqueueErrorMsg } = useSnackbar();

  const removeBulletin = async (bulletinId: number) => {
    if (
      !window.confirm("Er du sikker på at du vil fjerne leverandørsøknaden?")
    ) {
      return;
    }
    await del(`/${bulletinId}`);
    if (response.ok) {
      onRemoveBulletin(bulletinId);
      enqueueSuccessMsg("Leverandørsøknad fjernet.");
    } else if (error) {
      enqueueErrorMsg("Kunne ikke fjerne leverandørsøknad");
    }
  };

  const placeNames = bulletin.steder?.map((p) => p.navn) || [];
  let displayedPlaces = placeNames.slice(0, 3).join(", ");
  if (placeNames.length > 3) {
    displayedPlaces = "flere steder";
  }

  return (
    <Card sx={{ backgroundColor: "transparent" }}>
      <Link
        href={`underleverandorbulletin?bulletinExternalId=${bulletin.externalReference}`}
        sx={{ textDecoration: "none" }}
      >
        <Grid container>
          <Grid
            item
            xs={3}
            sx={{
              backgroundColor: "rgba(224, 240, 241)",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <LanIcon color="primary" fontSize="large" />
          </Grid>
          <Grid item xs={9} sx={{ backgroundColor: "white" }}>
            <CardContent>
              <Typography variant="h5" gutterBottom>
                {bulletin.title}
              </Typography>
              {bulletin.description && (
                <Typography variant="body2">
                  Beskrivelse:{" "}
                  {bulletin.description.length > 50
                    ? `${bulletin.description.slice(0, 50)}...`
                    : bulletin.description}
                </Typography>
              )}
              <Typography variant="body2">
                Opprettet av: {bulletin.creator.name}
              </Typography>
              {bulletin.limitDate && (
                <Typography variant="body2">
                  Gyldig til: {formatISODate(bulletin.limitDate)}
                </Typography>
              )}
              <Typography variant="body2">
                Publiseringsdato: {formatISODate(bulletin.createdAt)}
              </Typography>

              {/*  Places at the bottom of the data list */}
              {placeNames.length > 0 && (
                <Typography variant="body2" sx={{ marginTop: "0.5rem" }}>
                  Steder: {displayedPlaces}
                </Typography>
              )}
            </CardContent>
            <CardActions
              sx={{
                justifyContent: "flex-end",
                "& a": {
                  textDecoration: "none",
                },
              }}
            >
              <Button
                color="primary"
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  removeBulletin(bulletin.id);
                }}
              >
                Slett bulletin
              </Button>
            </CardActions>
          </Grid>
        </Grid>
      </Link>
    </Card>
  );
};
