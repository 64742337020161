import React from "react";
import { Grid, Box, Link, Menu, MenuItem } from "@mui/material";
import { Logo } from "../../../components/Logo/Logo";
import { useAuth0 } from "@auth0/auth0-react";
import { paths } from "../../../app/paths";
import { ToolBarMenu } from "./ToolBarMenu";
import { LinkType } from "../Portal";
import { PrimaryButton } from "../../../components/Buttons/PrimaryButton";
import { LightButton } from "../../../components/Buttons/LightButton";
import { useUserContext } from "../../../utils/contexts/UserContext";
import { config } from "../../../config";

export const TopToolBar: React.FunctionComponent = () => {
  const { loginWithRedirect, isAuthenticated } = useAuth0();
  const { hasProcurementPlanner } = useUserContext();

  const handleLoginWithRedirect = () => {
    loginWithRedirect({
      authorizationParams: { screen_hint: "signup", display: "page", ui_locales: "nb" },
    });
  };

  const links: Array<LinkType> = [
    { text: "Om oss", path: paths.about, onlyWhenAuthenticated: false },
    { text: "Nyheter", path: paths.news, onlyWhenAuthenticated: false },
    { text: "FAQ", path: paths.faq, onlyWhenAuthenticated: false },
    // Removed Kontakt from here
    { text: "Priser", path: paths.prices, onlyWhenAuthenticated: false },
    { text: "Søk", path: paths.search, onlyWhenAuthenticated: true },
    { text: "Min Oversikt", path: paths.searches, onlyWhenAuthenticated: true },
  ];

  const onlyWhenLoggedOutlinks: Array<LinkType> = [
    { text: "Finn Bransjekoder", path: paths.cpvoppslag, onlyWhenAuthenticated: false },
  ];

  const premiumlinks: Array<LinkType> = [
    { text: "Innkjøpsplaner", path: paths.procurementplanner, onlyWhenAuthenticated: true },
    { text: "Følg Oppdragsgivere", path: paths.companies, onlyWhenAuthenticated: true },
    { text: "Finn Underleverandører", path: paths.supplierbulletinlist, onlyWhenAuthenticated: true },
  ];

  // We'll place both "Finn Bransjekoder" and "Innkjøpsplaner" under Verktøy if authenticated
  const verktoyLinks = [
    { text: "Finn Bransjekoder", path: paths.cpvoppslag, onlyWhenAuthenticated: false },
    ...(hasProcurementPlanner
      ? premiumlinks.filter((l) => l.text === "Innkjøpsplaner")
      : []),
  ];

  // "Kontakt" is now moved to the right side near login/profile buttons
  const kontaktLink: LinkType = { text: "Kontakt", path: paths.contact, onlyWhenAuthenticated: false };

  // State for Verktøy menu
  const [verktøyAnchor, setVerktøyAnchor] = React.useState<null | HTMLElement>(null);

  const handleVerktøyMouseEnter = (event: React.MouseEvent<HTMLElement>) => {
    setVerktøyAnchor(event.currentTarget);
  };

  const handleVerktøyMouseLeave = () => {
    setTimeout(() => {
      setVerktøyAnchor(null);
    }, 150);
  };

  const handleMenuMouseEnter = () => {
    if (!verktøyAnchor) return;
    setVerktøyAnchor(verktøyAnchor);
  };

  const handleMenuMouseLeave = () => {
    setVerktøyAnchor(null);
  };

  // Prepare burger menu links (for small screens)
  // We include "Kontakt" and always include "Finn Bransjekoder" here as well,
  // so it's always visible in the burger menu, regardless of auth state.
  const burgerLinks = [
    ...links,
    kontaktLink,
    ...(!isAuthenticated ? onlyWhenLoggedOutlinks : []),
    // If authenticated, we still want Finn Bransjekoder in burger:
    ...(isAuthenticated
      ? [{ text: "Finn Bransjekoder", path: paths.cpvoppslag, onlyWhenAuthenticated: false }]
      : []),
  ];

  return (
    <>
      <Box marginLeft="1rem" marginRight="1rem">
        <Grid container direction="row" justifyContent="flex-start" alignItems="center">
          {/* Logo Section */}
          <Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
            <Link href={"/portal"} target="_parent" style={{ textDecoration: "none" }}>
              <Logo />
            </Link>
          </Grid>

          {/* Links Section */}
          <Grid item xs={8} sm={6} md={7} lg={7} xl={7.5}>
            <Grid container direction="row" alignItems="center" spacing={1} justifyContent="flex-start">
              {links
                .filter((link) => !link.onlyWhenAuthenticated)
                .map((link, index) => (
                  <Grid item key={`${index}_grid`} sx={{ display: { xs: "none", sm: "none", md: "block" } }}>
                    <LightButton text={link.text} link={`/portal/${link.path}`} variant="text" />
                  </Grid>
                ))}

              {!isAuthenticated &&
                onlyWhenLoggedOutlinks.map((link, index) => (
                  <Grid item key={`${index}_grid`} sx={{ display: { xs: "none", sm: "none", md: "block" } }}>
                    <LightButton text={link.text} link={`/portal/${link.path}`} variant="text" />
                  </Grid>
                ))}

              {isAuthenticated &&
                links
                  .filter((link) => link.onlyWhenAuthenticated)
                  .map((link, index) => (
                    <Grid item key={`${index}_grid`} sx={{ display: { xs: "none", sm: "none", md: "block" } }}>
                      <LightButton text={link.text} link={`/portal/${link.path}`} variant="text" />
                    </Grid>
                  ))}

              {isAuthenticated && hasProcurementPlanner &&
                premiumlinks
                  .filter((link) => link.text !== "Innkjøpsplaner")
                  .map((link, index) => (
                    <Grid item key={`${index}_grid`} sx={{ display: { xs: "none", sm: "none", md: "block" } }}>
                      <LightButton text={link.text} link={`/portal/${link.path}`} variant="text" />
                    </Grid>
                  ))}

              {isAuthenticated && (
                <Grid
                  item
                  sx={{ display: { xs: "none", sm: "none", md: "block" } }}
                  onMouseEnter={handleVerktøyMouseEnter}
                  onMouseLeave={handleVerktøyMouseLeave}
                >
                  <LightButton text={"Verktøy"} variant="text" />
                  <Menu
                    anchorEl={verktøyAnchor}
                    open={Boolean(verktøyAnchor)}
                    onClose={() => setVerktøyAnchor(null)}
                    MenuListProps={{
                      onMouseEnter: handleMenuMouseEnter,
                      onMouseLeave: handleMenuMouseLeave,
                    }}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'left',
                    }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'left',
                    }}
                  >
                    {verktoyLinks.map((link, index) => (
                      <MenuItem key={index} component="a" href={`/portal/${link.path}`}>
                        {link.text}
                      </MenuItem>
                    ))}
                  </Menu>
                </Grid>
              )}
            </Grid>
          </Grid>

          {/* Buttons + Kontakt Section */}
          <Grid item xs={2} sm={4} md={3} lg={3} xl={2.5}>
            <Grid
              container
              direction="row"
              alignContent="center"
              alignItems="center"
              spacing={1}
              justifyContent="flex-end"
            >
              {/* Move Kontakt here (for md and up) */}
              <Grid item sx={{ display: { xs: "none", sm: "none", md: "block" } }}>
                <LightButton text={kontaktLink.text} link={`/portal/${kontaktLink.path}`} variant="text" />
              </Grid>

              {!isAuthenticated ? (
                <>
                  <Grid item sx={{ display: { xs: "none", sm: "none", md: "block" } }}>
                    <PrimaryButton link={paths.signup} text={"Prøv oss"} />
                  </Grid>
                  <Grid item sx={{ display: { xs: "none", sm: "none", md: "block" } }}>
                    <LightButton onClick={handleLoginWithRedirect} text={"Logg inn"} />
                  </Grid>
                </>
              ) : (
                <>
                  <Grid item sx={{ display: { xs: "none", sm: "none", md: "block" } }}>
                    <LightButton text={"Min profil"} link={`/portal/${paths.profile}`} variant="text" />
                  </Grid>
                  <Grid item sx={{ display: { xs: "none", sm: "none", md: "block" } }}>
                    <LightButton link={paths.logout} text={"Logg ut"} />
                  </Grid>
                </>
              )}

              <Grid item sx={{ display: { xs: "block", sm: "block", md: "none" } }}>
                <ToolBarMenu
                  links={burgerLinks}
                  linksPremium={premiumlinks}
                  handleLoginWithRedirect={handleLoginWithRedirect}
                  isAuthenticated={isAuthenticated}
                  isPremium={hasProcurementPlanner}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};
