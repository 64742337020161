import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  TextField,
  Button,
  CircularProgress,
  Backdrop,
  Typography,
  Container,
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Accordion,
  AccordionDetails,
  Grid,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Chip,
} from "@mui/material";

import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";

import { useApi } from "../../../utils/hooks/useApi";
import { useReferenceDataContext } from "../../../utils/contexts/ReferenceDataContext";
import { SupplierBulletinCreate } from "./datatypes";
import { AnbudSummary, AnbudSummaryCpvCode, AnbudSummaryPlace } from "../Search/datatypes";
import { paths } from "../../../app/paths";
import { Hierarchy } from "../Search/SearchForm/Hierarchy";

// Tree helpers for CPV
import { assignParents, filterNodesWithAncestors, Node, Tree } from "../../../utils/tree";

interface IndustryDto {
  id: number;
  code: string;
  parentCode: string;
  level: number;
  name: string;
  shortName: string;
  notes: string;
  cpvCodes: any[];
  subIndustriesDescription: string;
  isSubContractorIndustry: boolean;
}

const CreateSupplierBulletin: React.FC = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const doffinAnbudId = searchParams.get("doffinid"); // possibly null

  // --------------------------------------------------------------------------
  // 1) Manage "Steder"
  // --------------------------------------------------------------------------
  const { steder, kategorier } = useReferenceDataContext(); // also has cpv data in `kategorier`
  const [openedSteder, setOpenedSteder] = useState<Set<number>>(new Set());
  const [checkedSteder, setCheckedSteder] = useState<Set<number>>(new Set());

  const handleSetOpened = (id: number, shouldOpen: boolean) => {
    setOpenedSteder((prev) => {
      const newSet = new Set(prev);
      if (shouldOpen) newSet.add(id);
      else newSet.delete(id);
      return newSet;
    });
  };

  const handleSetChecked = (id: number, shouldCheck: boolean) => {
    setCheckedSteder((prev) => {
      const newSet = new Set(prev);
      if (shouldCheck) newSet.add(id);
      else newSet.delete(id);
      return newSet;
    });
  };

  // --------------------------------------------------------------------------
  // 2) Bulletin / Tender Basic Info
  // --------------------------------------------------------------------------
  const [tender, setTender] = useState<AnbudSummary | null>(null);
  const [title, setTitle] = useState<string>("");
  const [description, setDescription] = useState<string>("");
  const [selectedIndustry, setSelectedIndustry] = useState<string>("");
  const [industries, setIndustries] = useState<IndustryDto[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [notFound, setNotFound] = useState<boolean>(false);
  const [serverError, setServerError] = useState<boolean>(false);
  const [alreadyExists, setAlreadyExists] = useState<boolean>(false);

  // Fields error states
  const [titleError, setTitleError] = useState<string | null>(null);
  const [descriptionError, setDescriptionError] = useState<string | null>(null);

  // --------------------------------------------------------------------------
  // 3) Contacts
  // --------------------------------------------------------------------------
  const [allContacts, setAllContacts] = useState<any[]>([]);
  const [selectedContacts, setSelectedContacts] = useState<any[]>([]);
  const [newContact, setNewContact] = useState<any>({
    emailaddress: "",
    telephoneNumber: "",
    contactFirstName: "",
    contactSurname: "",
    organisasjonsnummer: "",
  });
  const [isModalOpen, setModalOpen] = useState<boolean>(false);

  // --------------------------------------------------------------------------
  // 4) Alerting Types
  // --------------------------------------------------------------------------
  const [alertingType, setAlertingType] = useState<string>("Contacts");
  const alertingTypes = [
    { value: "Contacts", label: "Mine kontakter" },
    { value: "Users", label: "Finndoff-brukere (kommer snart)", disabled: true },
    { value: "Industries", label: "Bransjeliste (kommer snart)", disabled: true },
  ];

  // --------------------------------------------------------------------------
  // 5) CPV State (only used if alertingType !== 'Contacts')
  // --------------------------------------------------------------------------
  const cpvTree: Tree = kategorier;
  const [filterString, setFilterString] = useState<string>("");
  const [debouncedFilterString, setDebouncedFilterString] = useState<string>("");
  const [nodesFiltered, setNodesFiltered] = useState<Node[]>(cpvTree.rootNodes || []);
  const [openedCpvNodes, setOpenedCpvNodes] = useState<Set<number>>(new Set());
  const [checkedCpvIds, setCheckedCpvIds] = useState<Set<number>>(new Set());

  // **New**: Stats for each CPV code (cpvId -> number of matching firms)
  const [cpvStats, setCpvStats] = useState<Record<number, number>>({});
  const [cpvCompaniesCount, setCpvCompaniesCount] = useState<number>(null);
  
  // We'll store a total (sum of all stats) for convenience
  const totalFirms = Object.values(cpvStats).reduce((sum, val) => sum + val, 0);

  // --------------------------------------------------------------------------
  // 6) API
  // --------------------------------------------------------------------------
  const tenderEndpoint = doffinAnbudId
    ? `/api/tender/${doffinAnbudId}`
    : `/api/placeholder/notender`;
  const { get: rawGetTender, loading: tenderLoading } = useApi(tenderEndpoint);

  const bulletinEndpoint = doffinAnbudId
    ? `/api/bulletin/byExternalRef/${doffinAnbudId}`
    : `/api/placeholder/nobulletin`;
  const { get: rawCheckBulletin, loading: bulletinLoading } = useApi(bulletinEndpoint);

  const { post: createBulletin, loading: createLoading } = useApi("/api/bulletin");
  const { get: getSubContractorIndustries, loading: industryLoading } = useApi(
    `/api/industry/subcontractor`
  );
  const { get: fetchContacts, post: saveContact } = useApi("/api/me/bruker/contacts");

  const { post: getCpvStats } = useApi("/api/bulletin/stats/bycpv");

  // --------------------------------------------------------------------------
  // 7) Load user contacts on mount
  // --------------------------------------------------------------------------
  useEffect(() => {
    const fetchUserContacts = async () => {
      const contacts = await fetchContacts();
      if (contacts) {
        setAllContacts(contacts);
      }
    };
    fetchUserContacts().catch(console.error);
  }, [fetchContacts]);

  // --------------------------------------------------------------------------
  // 8) Setup CPV Tree
  // --------------------------------------------------------------------------
  useEffect(() => {
    if (cpvTree.rootNodes) {
      assignParents(cpvTree.rootNodes);
      setNodesFiltered(cpvTree.rootNodes);
    }
  }, [cpvTree]);

  // Debounce filter
  useEffect(() => {
    const t = setTimeout(() => setDebouncedFilterString(filterString), 300);
    return () => clearTimeout(t);
  }, [filterString]);

  // Filter CPV based on search
  useEffect(() => {
    if (!cpvTree.rootNodes) return;
    if (debouncedFilterString.length >= 3) {
      const result = filterNodesWithAncestors(cpvTree.rootNodes, debouncedFilterString);
      const expandedNodes = new Set<number>();
      const expandAll = (node: Node) => {
        expandedNodes.add(node.id);
        node.children.forEach(expandAll);
      };
      result.forEach(expandAll);
      setNodesFiltered(result);
      setOpenedCpvNodes(expandedNodes);
    } else {
      setNodesFiltered(cpvTree.rootNodes);
      setOpenedCpvNodes(new Set());
    }
  }, [debouncedFilterString, cpvTree]);

  // Preselect CPV codes from the tender
  useEffect(() => {
    if (!tender?.cpvKoder?.length) return;
    const newSet = new Set<number>();
    tender.cpvKoder.forEach((cpv: AnbudSummaryCpvCode) => {
      if (cpv.id) newSet.add(cpv.id);
    });
    setCheckedCpvIds(newSet);
  }, [tender]);

  // --------------------------------------------------------------------------
  // 9) Industry Data
  // --------------------------------------------------------------------------
  useEffect(() => {
    const fetchIndustries = async () => {
      const industryResult = await getSubContractorIndustries();
      if (!industryResult || industryResult.status === 404) {
        setNotFound(true);
      } else if (industryResult && industryResult.status === 500) {
        setServerError(true);
      } else if (industryResult) {
        setIndustries(industryResult);
      }
      setLoading(false);
    };

    fetchIndustries().catch(console.error);
  }, [getSubContractorIndustries]);

  // --------------------------------------------------------------------------
  // 10) Fetch Tender & Check Bulletin
  // --------------------------------------------------------------------------
  useEffect(() => {
    const fetchTenderAndCheckBulletin = async () => {
      if (!doffinAnbudId) {
        setLoading(false);
        return;
      }
      setLoading(true);

      const bulletinResult = await rawCheckBulletin();
      if (bulletinResult && (bulletinResult as any).id) {
        setAlreadyExists(true);
        setLoading(false);
        return;
      }

      const tenderResult = await rawGetTender();
      if (tenderResult && (tenderResult as any).status === 500) {
        setServerError(true);
      } else if (tenderResult && (tenderResult as any).status === 404) {
        // Not found
      } else if (tenderResult && !(tenderResult as any).status) {
        setTender(tenderResult);
        setTitle(`Underleverandører søkes for: ${tenderResult.tittel}.`);
        setDescription(
          `Bedrift leter etter samarbeidspartner eller underleverandør for kunngjøring med doffinid ${doffinAnbudId}.
Tilbudet skal dekke CPV koder: ${tenderResult.cpvKoder
            .map((k: AnbudSummaryCpvCode) => k.kode)
            .join(", ")}.
Utførelsessted: ${tenderResult.steder
            .map((s: AnbudSummaryPlace) => s.navn)
            .join(", ")}.`
        );
      }
      setLoading(false);
    };
    fetchTenderAndCheckBulletin().catch(console.error);
  }, [doffinAnbudId, rawCheckBulletin, rawGetTender]);

  // --------------------------------------------------------------------------
  // 11) handleSetCpvChecked + fetch stats
  // --------------------------------------------------------------------------
  const handleSetCpvOpened = (id: number, opened: boolean) => {
    setOpenedCpvNodes((prev) => {
      const copy = new Set(prev);
      if (opened) copy.add(id);
      else copy.delete(id);
      return copy;
    });
  };

  // We’ll fetch stats each time the user modifies the checked set
  const handleSetCpvChecked = (id: number, checked: boolean) => {
    setCheckedCpvIds((prev) => {
      const copy = new Set(prev);
      if (checked) copy.add(id);
      else copy.delete(id);
      return copy;
    });
  };

  // When the set of CPV codes changes, fetch stats
  useEffect(() => {
    if (checkedCpvIds.size === 0) {
      setCpvStats({});
      setCpvCompaniesCount(null);
      return;
    }
  
    // Prepare the request object (CpvSearchStats)
    const requestBody = { cpvCodeIds: Array.from(checkedCpvIds) };
  
    // POST the body to /api/supplierbulletin/stats/bycpv
    getCpvStats(requestBody)
      .then((result) => {
        if (result) {
          setCpvStats(result.cpvCodeCounts);
          setCpvCompaniesCount(result.companiesCount);
        } else {
          setCpvStats({});
          setCpvCompaniesCount(null);
        }
      })
      .catch((err) => {
        console.error(err);
        setCpvStats({});
        setCpvCompaniesCount(null);
      });
  }, [checkedCpvIds, getCpvStats]);

  // --------------------------------------------------------------------------
  // 12) Are we in "Industries" mode? Is form disabled?
  // --------------------------------------------------------------------------
  const isIndustriesMode = alertingType === "Industries";
  const isFormReallyDisabled =
    loading ||
    createLoading ||
    industryLoading ||
    (doffinAnbudId && (tenderLoading || bulletinLoading)) ||
    (isIndustriesMode && !selectedIndustry);

  // --------------------------------------------------------------------------
  // 13) Submit with validation
  // --------------------------------------------------------------------------
  const handleSubmit = async () => {
    let hasError = false;
    const trimmedTitle = title.trim();
    const trimmedDesc = description.trim();

    if (!trimmedTitle) {
      setTitleError("Tittel må fylles inn.");
      hasError = true;
    } else {
      setTitleError(null);
    }

    if (!trimmedDesc) {
      setDescriptionError("Beskrivelse må fylles inn.");
      hasError = true;
    } else {
      setDescriptionError(null);
    }

    if (hasError) return;

    const stedIdsArray = Array.from(checkedSteder);

    const newBulletin: Partial<SupplierBulletinCreate> = {
      title: trimmedTitle,
      description: trimmedDesc,
      doffinAnbudId: doffinAnbudId || undefined,
      industryId: isIndustriesMode ? selectedIndustry || undefined : undefined,
      stederIds: stedIdsArray,
      brukerContactsIds: selectedContacts.map((c) => c.id),
      alertingType,
      cpvIds: Array.from(checkedCpvIds),
    };

    const result = await createBulletin(newBulletin);
    if (result) {
      navigate(`${paths.portal}/${paths.supplierbulletinlist}/`);
    }
  };

  // --------------------------------------------------------------------------
  // 14) Contact management
  // --------------------------------------------------------------------------
  const handleAddContactToSelected = (contact: any) => {
    setSelectedContacts((prevSelected) => [...prevSelected, contact]);
    setAllContacts((prevAll) => prevAll.filter((c) => c.emailaddress !== contact.emailaddress));
  };

  const handleRemoveContactFromSelected = (contact: any) => {
    setSelectedContacts((prevSelected) =>
      prevSelected.filter((sel) => sel.emailaddress !== contact.emailaddress)
    );

    setAllContacts((prevAll) => {
      if (!prevAll.some((c) => c.emailaddress === contact.emailaddress)) {
        return [...prevAll, contact];
      }
      return prevAll;
    });
  };

  const handleOpenModal = () => setModalOpen(true);
  const handleCloseModal = () => {
    setModalOpen(false);
    setNewContact({
      emailaddress: "",
      telephoneNumber: "",
      contactFirstName: "",
      contactSurname: "",
      organisasjonsnummer: "",
    });
  };

  const handleSaveNewContact = async () => {
    const result = await saveContact(newContact);
    if (result) {
      setAllContacts((prev) => [...prev, result]);
      handleCloseModal();
    }
  };

  // --------------------------------------------------------------------------
  // Early returns if errors or alreadyExists
  // --------------------------------------------------------------------------
  if (alreadyExists) {
    return (
      <Container>
        <Box display="flex" flexDirection="column" alignItems="center" mt={5}>
          <Typography variant="h5" color="error">
            Det finnes allerede en søknad for denne kunngjøringen
          </Typography>
        </Box>
      </Container>
    );
  }

  if (serverError) {
    return (
      <Container>
        <Box display="flex" flexDirection="column" alignItems="center" mt={5}>
          <Typography variant="h5" color="error">
            Noe gikk galt...
          </Typography>
        </Box>
      </Container>
    );
  }

  if (notFound && doffinAnbudId) {
    return (
      <Container>
        <Box display="flex" flexDirection="column" alignItems="center" mt={5}>
          <Typography variant="h5" color="error">
            Kunngjøring eller bransjeliste ikke funnet
          </Typography>
        </Box>
      </Container>
    );
  }

  // --------------------------------------------------------------------------
  // 15) Render
  // --------------------------------------------------------------------------
  return (
    <Container>
      {(loading || tenderLoading || createLoading || bulletinLoading || industryLoading) && (
        <Backdrop
          open
          style={{
            zIndex: 1000,
            position: "absolute",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "rgba(0, 0, 0, 0.85)",
          }}
        >
          <CircularProgress sx={{ color: "white" }} />
          <Typography variant="h6" style={{ marginTop: "10px", color: "white" }}>
            Laster innhold...
          </Typography>
        </Backdrop>
      )}

      <Box display="flex" flexDirection="column" alignItems="center">
        <Typography variant="h4" gutterBottom>
          Opprett søknad for underleverandører
        </Typography>

        {/* ALERTING TYPE */}
        <FormControl fullWidth sx={{ marginBottom: "1rem" }}>
          <InputLabel id="alerting-type-select-label">Varslingstype</InputLabel>
          <Select
            labelId="alerting-type-select-label"
            id="alerting-type-select"
            value={alertingType}
            label="Varslingstype"
            onChange={(e) => {
              setAlertingType(e.target.value);
              // If leaving "Industries" mode, reset that selection
              if (e.target.value !== "Industries") {
                setSelectedIndustry("");
              }
            }}
            disabled={isFormReallyDisabled}
          >
            {alertingTypes.map((type) => (
              <MenuItem key={type.value} value={type.value} disabled={type.disabled === true}>
                {type.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        {/* If in "Industries" mode, show an industry select */}
        {isIndustriesMode && (
          <FormControl fullWidth sx={{ marginBottom: "1rem" }}>
            <InputLabel id="industry-select-label">Bransje</InputLabel>
            <Select
              labelId="industry-select-label"
              id="industry-select"
              value={selectedIndustry}
              label="Bransje"
              onChange={(e) => setSelectedIndustry(e.target.value)}
              disabled={isFormReallyDisabled}
            >
              {industries.map((ind) => (
                <MenuItem key={ind.code} value={ind.code}>
                  {ind.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}

        {/* TITLE */}
        <TextField
          label="Tittel"
          variant="outlined"
          fullWidth
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          sx={{ marginBottom: "1rem" }}
          disabled={isFormReallyDisabled}
          error={Boolean(titleError)}
          helperText={titleError || ""}
        />

        {/* DESCRIPTION */}
        <TextField
          label="Beskrivelse"
          variant="outlined"
          fullWidth
          multiline
          rows={4}
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          sx={{ marginBottom: "1rem" }}
          disabled={isFormReallyDisabled}
          error={Boolean(descriptionError)}
          helperText={descriptionError || ""}
        />

        {/* ===================== CPV SECTION ===================== */}
        {alertingType !== "Contacts" && (
          <Box
            width="100%"
            marginBottom="2rem"
            p={2}
            border="1px solid #ccc"
            borderRadius="4px"
            position="relative"
          >
            <Typography
              variant="h5"
              sx={{
                position: "absolute",
                top: "-12px",
                left: "16px",
                px: "8px",
                backgroundColor: "white",
              }}
            >
              Velg CPV-koder
            </Typography>

            {/* CPV search input */}
            <TextField
              label="Søk med kode eller nøkkelord (minst 3 tegn)"
              variant="standard"
              fullWidth
              value={filterString}
              onChange={(e) => setFilterString(e.target.value)}
              disabled={isFormReallyDisabled}
              sx={{ marginBottom: "1rem" }}
            />

            {/* Sum of all matching firms */}
            {checkedCpvIds.size > 0 && cpvCompaniesCount > 0 && (
              <Typography variant="body1" sx={{ marginBottom: "0.5rem" }}>
                Disse kodene matcher <strong>{cpvCompaniesCount}</strong> firmaer
              </Typography>
            )}

            {/* Show selected CPVs as chips with stats */}
            {checkedCpvIds.size > 0 && (
              <Box marginBottom={2}>
                <Typography variant="subtitle1">Valgte CPV-koder:</Typography>
                <Box display="flex" flexWrap="wrap">
                  {Array.from(checkedCpvIds).map((id) => {
                    const node = cpvTree.nodeMap[id];
                    if (!node) return null;

                    // If stats are loaded, show them in parens
                    const count = cpvStats[id] ?? 0;
                    const labelText = `${node.label} (${count} firmaer)`;

                    return (
                      <Chip
                        key={id}
                        label={labelText}
                        onDelete={() => handleSetCpvChecked(id, false)}
                        sx={{ margin: "4px" }}
                      />
                    );
                  })}
                </Box>
              </Box>
            )}

            {/* CPV Hierarchy */}
            <Hierarchy
              tree={cpvTree}
              nodes={nodesFiltered}
              openedNodeIds={openedCpvNodes}
              checkedNodeIds={checkedCpvIds}
              setChecked={handleSetCpvChecked}
              setOpened={handleSetCpvOpened}
              searchTerm={filterString}
              disabled={isFormReallyDisabled}
            />
          </Box>
        )}

        {/* =============== CONTACTS (if "Contacts") =============== */}
        {alertingType === "Contacts" && (
          <Box
            width="100%"
            marginBottom="2rem"
            position="relative"
            border="1px solid #ccc"
            borderRadius="4px"
            padding="1rem"
          >
            <Typography
              variant="h5"
              sx={{
                position: "absolute",
                top: "-12px",
                left: "16px",
                backgroundColor: "white",
                px: "8px",
              }}
            >
              Hvem skal varsles
            </Typography>

            <Grid container spacing={2} style={{ marginTop: "5px", marginLeft: "5px" }}>
              {/* Selected Contacts */}
              <Grid item xs={6}>
                <Typography variant="body1">Valgte Kontakter</Typography>
                <List>
                  {selectedContacts.map((contact) => (
                    <ListItem key={contact.id}>
                      <IconButton
                        onClick={() => handleRemoveContactFromSelected(contact)}
                        edge="start"
                        style={{ marginRight: "10px" }}
                        disabled={isFormReallyDisabled}
                      >
                        <RemoveIcon />
                      </IconButton>
                      <ListItemText
                        primary={`${contact.contactFirstName} ${contact.contactSurname}`}
                        secondary={contact.emailaddress}
                      />
                    </ListItem>
                  ))}
                </List>
              </Grid>

              {/* Available Contacts */}
              <Grid item xs={6} style={{ marginTop: "5px" }}>
                <Typography variant="body1">Tilgjengelige kontakter</Typography>
                <List>
                  {allContacts.map((contact) => (
                    <ListItem key={contact.id}>
                      <IconButton
                        onClick={() => handleAddContactToSelected(contact)}
                        edge="start"
                        style={{ marginRight: "10px" }}
                        disabled={isFormReallyDisabled}
                      >
                        <AddIcon />
                      </IconButton>
                      <ListItemText
                        primary={`${contact.contactFirstName} ${contact.contactSurname}`}
                        secondary={contact.emailaddress}
                      />
                    </ListItem>
                  ))}
                </List>
              </Grid>

              {/* Add New Contact Button */}
              <Grid
                item
                xs={12}
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  marginTop: "10px",
                  marginRight: "20px",
                }}
              >
                <Button
                  variant="text"
                  color="primary"
                  onClick={handleOpenModal}
                  style={{ display: "flex", alignItems: "center", padding: 0 }}
                  disabled={isFormReallyDisabled}
                >
                  <AddIcon fontSize="small" style={{ marginRight: "4px" }} />
                  Legg til ny kontakt
                </Button>
              </Grid>
            </Grid>
          </Box>
        )}

        {/* ADD CONTACT DIALOG */}
        <Dialog open={isModalOpen} onClose={handleCloseModal}>
          <DialogTitle>Legg til ny kontakt</DialogTitle>
          <DialogContent>
            <TextField
              fullWidth
              label="Organisasjonsnummer"
              margin="dense"
              value={newContact.organisasjonsnummer}
              onChange={(e) =>
                setNewContact({ ...newContact, organisasjonsnummer: e.target.value })
              }
            />
            <TextField
              fullWidth
              label="Fornavn"
              margin="dense"
              value={newContact.contactFirstName}
              onChange={(e) =>
                setNewContact({ ...newContact, contactFirstName: e.target.value })
              }
            />
            <TextField
              fullWidth
              label="Etternavn"
              margin="dense"
              value={newContact.contactSurname}
              onChange={(e) =>
                setNewContact({ ...newContact, contactSurname: e.target.value })
              }
            />
            <TextField
              fullWidth
              label="E-postadresse"
              margin="dense"
              value={newContact.emailaddress}
              onChange={(e) =>
                setNewContact({ ...newContact, emailaddress: e.target.value })
              }
            />
            <TextField
              fullWidth
              label="Telefonnummer"
              margin="dense"
              value={newContact.telephoneNumber}
              onChange={(e) =>
                setNewContact({ ...newContact, telephoneNumber: e.target.value })
              }
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseModal}>Avbryt</Button>
            <Button onClick={handleSaveNewContact}>Lagre</Button>
          </DialogActions>
        </Dialog>

        {/* STEDER */}
        <Box
          width="100%"
          marginBottom="2rem"
          position="relative"
          border="1px solid #ccc"
          borderRadius="4px"
          padding="1rem"
        >
          {checkedSteder.size > 0 && (
            <Box marginBottom={2}>
              <Typography variant="subtitle1">Valgte steder:</Typography>
              <Box display="flex" flexWrap="wrap">
                {Array.from(checkedSteder).map((id) => {
                  const node = steder.nodeMap[id];
                  if (!node) return null;

                  return (
                    <Chip
                      key={id}
                      label={node.label}
                      onDelete={() => handleSetChecked(id, false)}
                      sx={{ margin: "4px" }}
                    />
                  );
                })}
              </Box>
            </Box>
          )}

          <Accordion
            sx={{
              boxShadow: "none",
              border: "none",
              marginTop: "0.5rem",
            }}
            defaultExpanded
          >
            <AccordionDetails>
              <Hierarchy
                tree={steder}
                nodes={steder.rootNodes?.[0]?.children ?? []}
                openedNodeIds={openedSteder}
                checkedNodeIds={checkedSteder}
                setChecked={handleSetChecked}
                setOpened={handleSetOpened}
                disabled={isFormReallyDisabled}
              />
            </AccordionDetails>
          </Accordion>
        </Box>

        {/* SUBMIT */}
        <Button
          variant="contained"
          color="primary"
          onClick={handleSubmit}
          disabled={isFormReallyDisabled}
          sx={{ marginBottom: "2rem" }}
        >
          Opprett søknad
        </Button>
      </Box>
    </Container>
  );
};

export default CreateSupplierBulletin;
