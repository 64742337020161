import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useReferenceDataContext } from "../../utils/contexts/ReferenceDataContext";
import { Tree, Node, filterNodesWithAncestors, immutablySet, assignParents} from "../../utils/tree";
import { Hierarchy } from "../Portal/Search/SearchForm/Hierarchy";
import {
  Box,
  TextField,
  Typography,
  Chip,
  Container,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  useMediaQuery,
  useTheme,
  IconButton,
  Collapse,
} from "@mui/material";
import { useUserContext } from "../../utils/contexts/UserContext";
import { RegisterCard } from "../../components/Register/RegisterCard";
import CloseIcon from "@mui/icons-material/Close";
import { useForm } from "react-hook-form";
import ReCAPTCHA from "react-google-recaptcha";
import { useSnackbar } from "../../utils/hooks/useSnackbar";
import { useApi } from "../../utils/hooks/useApi";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";

interface FormData {
  email: string;
  captchaToken: string;
}

interface CpvKodeDto {
  id: number;
  kode: string;
  beskrivelse: string;
}

export const CpvLookup: React.FC = () => {
  const { kategorier } = useReferenceDataContext();
  const tree: Tree = kategorier;
  const { finndoffUser } = useUserContext();
  const { enqueueSuccessMsg, enqueueErrorMsg } = useSnackbar();
  const { post } = useApi("/api/emails/send/cpvlookup");
  const [filterString, setFilterString] = useState<string>("");
  const [debouncedFilterString, setDebouncedFilterString] = useState<string>(filterString);
  const [nodesFiltered, setNodesFiltered] = useState<Node[]>(tree.rootNodes);
  const [openedNodes, setOpenedNodes] = useState<Set<number>>(new Set());
  const [checkedNodeIds, setCheckedNodeIds] = useState<Set<number>>(new Set());
  const [openModal, setOpenModal] = useState<boolean>(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const { register, handleSubmit, formState: { errors }, reset, setValue } = useForm<FormData>();
  const [captchaToken, setCaptchaToken] = useState<string>("");
  const [expanded, setExpanded] = useState(false);
  
  useEffect(() => {
    if (tree && tree.rootNodes) {
      assignParents(tree.rootNodes); // Assign parent references
      setNodesFiltered(tree.rootNodes); // Initialize filtered nodes
    }
  }, [tree]);
  useEffect(() => {
    if (tree && tree.rootNodes) {
      setNodesFiltered(tree.rootNodes); // Initialize filtered nodes
    }
  }, [tree]);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedFilterString(filterString);
    }, 300); // Adjust the delay as needed

    return () => {
      clearTimeout(handler);
    };
  }, [filterString]);

  useEffect(() => {
    if (debouncedFilterString.length >= 3) {
      const result = filterNodesWithAncestors(tree.rootNodes, debouncedFilterString);

      const expandedNodes = new Set<number>();

      // Collect IDs of all nodes in the filtered tree to expand them
      const collectAllNodeIds = (node: Node) => {
        expandedNodes.add(node.id);
        node.children.forEach((child) => collectAllNodeIds(child));
      };

      result.forEach((node) => {
        collectAllNodeIds(node);
      });

      setNodesFiltered(result); // Update filtered nodes
      setOpenedNodes(expandedNodes); // Expand all relevant nodes

      // Debug output
      console.log("Filtered Nodes:", result);
      console.log("Expanded Nodes:", Array.from(expandedNodes));
    } else {
      setNodesFiltered(tree.rootNodes);
      setOpenedNodes(new Set());
    }
  }, [debouncedFilterString]);

  const handleFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setFilterString(value);
  };

  const setOpenedNode = (id: number, opened: boolean) => {
    setOpenedNodes(immutablySet(openedNodes, id, opened));
  };

  const setCheckedNode = (id: number, checked: boolean) => {
    const newCheckedNodeIds = new Set(checkedNodeIds);
    if (checked) {
      newCheckedNodeIds?.add(id);
    } else {
      newCheckedNodeIds?.delete(id);
    }
    setCheckedNodeIds(newCheckedNodeIds);
  };

  const handleOpenModal = () => {
    setOpenModal(true);
    reset();
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const onCaptchaChange = (token: string | null) => {
    setCaptchaToken(token || "");
    setValue("captchaToken", token || "");
  };

  const onSubmit = async (data: FormData) => {
    // Prepare selected CPV codes
    const selectedCpvCodes = Array.from(checkedNodeIds).map((id) => {
      const node = tree.nodeMap[id];
      let kode = node?.id.toString();
      let beskrivelse = node?.label || "";

      if (node?.label) {
        const [kodePart, beskrivelsePart] = node.label.split(" - ");
        if (kodePart && beskrivelsePart) {
          kode = kodePart.trim();
          beskrivelse = beskrivelsePart.trim();
        }
      }

      return { id: node?.id, kode, beskrivelse };
    });

    const payload = {
      email: data.email,
      captchaToken,
      cpvKoder: selectedCpvCodes,
    };

    try {
      const response = await post("", payload);
      if (response) {
        enqueueSuccessMsg("E-posten ble sendt!");
        handleCloseModal();
      } else {
        enqueueErrorMsg("Kunne ikke sende e-posten. Vennligst prøv igjen.");
      }
    } catch (err) {
      enqueueErrorMsg("En feil oppstod. Vennligst prøv igjen.");
      console.error(err);
    }
  };

  return (
    <>
      <Helmet>
        <title>CPV Oppslag | Finn Bransjekoder for Anbud</title>
        <meta name="description" content="Finn bransjekoder (CPV) for anbud. Søk i vårt CPV-register for å finne riktige koder til dine offentlige anskaffelser og anbud." />
        <meta name="keywords" content="CPV oppslag, CPV-search, bransjekoder, anbud, offentlige anskaffelser" />
        {/* Open Graph Meta Tags */}
        <meta property="og:title" content="CPV Oppslag | Finn Bransjekoder for Anbud" />
        <meta property="og:description" content="Finn bransjekoder (CPV) for anbud. Søk i vårt CPV-register for å finne riktige koder til dine offentlige anskaffelser og anbud." />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://www.yoursite.com/cpv-oppslag" />
        <meta property="og:image" content="https://www.yoursite.com/path-to-image.jpg" />
        {/* Structured Data */}
        <script type="application/ld+json">
          {`{ "@context": "http://schema.org", "@type": "WebPage", "name": "CPV Oppslag | Finn Bransjekoder for Anbud", "description": "Finn bransjekoder (CPV) for anbud. Søk i vårt CPV-register for å finne riktige koder til dine offentlige anskaffelser og anbud.", "url": "https://www.yoursite.com/cpv-oppslag" }`}
        </script>
      </Helmet>

      <Container maxWidth="md" sx={{ padding: "16px" }}>
        {!finndoffUser && (
          <Box sx={{ display: "flex", justifyContent: "center", mb: 2 }}>
            <RegisterCard maintext="Prøv anbudsvarsling spesialtilpasset din bedrift!" buttontext="Start din GRATIS prøveperiode nå!" />
          </Box>
        )}

        <Typography variant="h4" component="h1" gutterBottom>
          Finn bransjekoder (CPV)
        </Typography>

        <Box sx={{ marginBottom: 3 }}>
          <Typography variant="body1" gutterBottom>
            CPV (Common Procurement Vocabulary) er et kodingssystem utviklet av EU for å
            klassifisere anskaffelser av varer, tjenester, og bygge- og anleggsprosjekter.
          </Typography>

          {/* Collapsible section starts here */}
          <Collapse in={expanded} timeout="auto" unmountOnExit>
            <Typography variant="body1" gutterBottom>
              CPV-kodene forenkler søk og strukturering i databaser og gjør det lettere å handle
              over landegrensene. Kodene har 8 sifre, strukturert hierarkisk fra bred
              produktgruppe til spesifikke undergrupper, som for eksempel:
            </Typography>
            <Box sx={{ ml: 2 }}>
              <Typography variant="body2">
                • <strong>22000000</strong>: Trykksaker og relaterte produkter
              </Typography>
              <Typography variant="body2">
                • <strong>22100000</strong>: Trykte bøker, brosjyrer og foldere
              </Typography>
              <Typography variant="body2">
                • <strong>22110000</strong>: Trykte bøker
              </Typography>
              <Typography variant="body2">
                • <strong>22112000</strong>: Lærebøker
              </Typography>
            </Box>
            <Typography variant="body1" gutterBottom mt={2}>
              Med vårt CPV-kodesøk kan du enkelt finne aktuelle koder gjennom fritekstsøk eller
              ved å skrive inn 2 eller flere siffer for å filtrere kodene. For en mer oversiktlig
              søkeopplevelse kan du også navigere i trestrukturen for gradvis å detaljere fra
              hovedkoder til underkoder.
            </Typography>
          </Collapse>

          <Box display="flex" justifyContent="center">
            <Button
              variant="text"
              endIcon={expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
              onClick={() => setExpanded(!expanded)}
            >
              {expanded ? "Mindre" : "Mer info"}
            </Button>
          </Box>
        </Box>
        {/* Collapsible section ends here */}

        {checkedNodeIds.size > 0 && (
          <Box marginBottom={2}>
            <Typography variant="subtitle1">Valgte bransjekoder:</Typography>
            <Box display="flex" flexWrap="wrap">
              {Array.from(checkedNodeIds).map((id) => {
                const node = tree.nodeMap[id];
                return node && (
                  <Chip key={id} label={node.label} onDelete={() => setCheckedNode(id, false)} style={{ margin: "4px" }} />
                );
              })}
            </Box>
            <Box marginTop={2}>
              <Button variant="contained" color="primary" onClick={handleOpenModal}>
                Send meg disse kodene på epost
              </Button>
            </Box>
          </Box>
        )}

        <Box sx={{ marginBottom: "1rem" }}>
          <TextField
            onChange={handleFilterChange}
            placeholder="Søk med kode eller nøkkelord (minst 3 tegn)"
            value={filterString}
            color="primary"
            variant="standard"
            size="small"
            fullWidth
          />
        </Box>

        <Hierarchy
          tree={tree}
          nodes={nodesFiltered}
          openedNodeIds={openedNodes}
          checkedNodeIds={checkedNodeIds}
          setChecked={setCheckedNode}
          setOpened={setOpenedNode}
          searchTerm={debouncedFilterString}
        />
      </Container>
    
    <Dialog open={openModal} onClose={handleCloseModal} fullScreen={fullScreen} aria-labelledby="email-dialog-title">
        <DialogTitle id="email-dialog-title">
          Send CPV-koder på e-post
          <IconButton aria-label="close" onClick={handleCloseModal} sx={{ position: "absolute", right: theme.spacing(1), top: theme.spacing(1), color: theme.palette.grey[500] }}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <form id="emailForm" onSubmit={handleSubmit(onSubmit)}>
            <TextField
              label="E-postadresse"
              fullWidth
              margin="normal"
              {...register("email", {
                required: "E-postadresse er påkrevd",
                pattern: { value: /^\S+@\S+$/i, message: "Ugyldig e-postadresse" },
              })}
              error={!!errors.email}
              helperText={errors.email?.message}
            />
            <Box sx={{ mt: 2 }}>
              <ReCAPTCHA sitekey="6Lfb4n4qAAAAAGt9a55M1xON0V75Q98q31c9kOlM" onChange={onCaptchaChange} />
              {errors.captchaToken && (
                <Typography variant="body2" color="error">
                  {errors.captchaToken.message}
                </Typography>
              )}
            </Box>
          </form>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseModal}>Avbryt</Button>
          <Button type="submit" form="emailForm" variant="contained" color="primary" disabled={!captchaToken}>
            Send
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
